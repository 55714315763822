import "./../App.css";
import {CompareStartDate, FindCountry} from "./../Methods.js";

export const EventDashboardPage = ({state,Back}) => {
    const totalReg = (eventID) => {
        return Object.values(state.users).filter(item => item.eventID === eventID && item.role === "user").length;
    } 
    const totalRegUnique = (eventID) => {
        return Object.values(state.users).filter(item => item.eventID === eventID && item.role === "user").filter((v,i,a)=>a.findIndex(v2=>(v2.email===v.email))===i).length;
    } 
    const totalRegUniqueOpted = (eventID) => {
      return Object.values(state.users).filter(item => item.eventID === eventID && item.role === "user" && item.optIn).filter((v,i,a)=>a.findIndex(v2=>(v2.email===v.email))===i).length;
  } 
    const totalParticipate = (eventID) => {
        return Object.values(state.users).filter(item => item.eventID === eventID  && item.role === "user" && item.checkedIn).length;
    } 
    const totalParticipateUnique = (eventID) => {
        return Object.values(state.users).filter(item => item.eventID === eventID && item.checkedIn && item.role === "user").filter((v,i,a)=>a.findIndex(v2=>(v2.email===v.email))===i).length;
    } 
    const totalParticipateUniqueOpted = (eventID) => {
      return Object.values(state.users).filter(item => item.eventID === eventID && item.checkedIn && item.role === "user" && item.optIn).filter((v,i,a)=>a.findIndex(v2=>(v2.email===v.email))===i).length;
  } 
    const totalRegPerDay = (eventID) => {
        const daysArray = [];
        const usersArray = [];
        const fullArray = [];
        let userString = "";
        const eventUsers = Object.values(state.users).filter(item => item.eventID === eventID && item.role === "user");
        for (let i = 0; i < eventUsers.length; i++) {
            var date = new Date(eventUsers[i].timeStamp);
            let month = date.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
            let day = date.getDate(); // 9
            daysArray.push(day + "/" + month);
            let userObject = eventUsers[i];
            userObject.day = day + "/" + month;
            usersArray.push(userObject);
        }
        let uniqueDays = [...new Set(daysArray)];
        uniqueDays.sort(function (a, b) {
          a = a.split('/');
          b = b.split('/');
          return a[1] - b[1] || a[0] - b[0];
      });
      
        for (let i = 0; i < uniqueDays.length; i++) {
            fullArray.push(uniqueDays[i] + ": " + usersArray.filter(item => item.day === uniqueDays[i]).length);
        }
        for (let i = 0; i < fullArray.length; i++) {
            userString += fullArray[i] + ", ";
        }
        return userString;
    } 
    const totalNumCountries = (eventID) => {
      let countryArray = [];
      Object.values(state.users).filter(item => item.eventID === eventID  && item.role === "user").map((user,userIndex) => {
        if (user.country !== null) countryArray.push(user.country);
      })
      let uniqueCountries = [...new Set(countryArray)];
      return uniqueCountries.length;
    }
    const topFiveCountries = (eventID) => {
      const data = Object.values(state.users).filter(item => item.eventID === eventID  && item.role === "user");
      var countObj = data.reduce((a,c)=>{
        if (c.country !== null) a[c.country] = (a[c.country] || 0 ) + 1;
        return a
     },{});   
    //  var top5Array = [];  
     var top5 = Object.keys(countObj)
                       .sort((a,b) => countObj[b] - countObj[a] )
                      .slice(0,5);
      return top5;
    }
  return (
    <div>
        <button className="secondary" onClick={() => Back(true)}>Back</button>
        <section>
          <h1>Event dashboard</h1>
          {Object.values(state.events)
            .filter(item => (state.role === "reception" || state.role === "manager") && state.receptionEventID ? item.eventID.toString() === state.receptionEventID.toString() : item)
            .sort(CompareStartDate)
            .map((eventItem,eventIndex) => (
            <section className="event" key={eventIndex}>
              <div className="justify">
                <h2 className={(state.role === "super" || state.role === "manager") ? "limit" : null}>{eventItem.eventName}</h2>
              </div>
              <table cellPadding="0" cellSpacing="0">
                <thead><tr className="bold"><td>Stats</td></tr></thead>
                <tbody>
                 <tr className="padding"><td>Total number of registrations: {totalReg(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total number of registrations (unique): {totalRegUnique(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total number of registrations (unique and opted-in): {totalRegUniqueOpted(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total number of participants: {totalParticipate(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total number of participants (unique): {totalParticipateUnique(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total number of participants (unique and opted-in): {totalParticipateUniqueOpted(eventItem.eventID)}</td></tr>
                 <tr className="padding"><td>Total registrations per day: {totalRegPerDay(eventItem.eventID)}</td></tr>
                 {totalNumCountries(eventItem.eventID) > 0 && <tr className="padding"><td>Total number of countries: {totalNumCountries(eventItem.eventID)}</td></tr>}
                 {topFiveCountries(eventItem.eventID).length > 0 && <tr className="padding"><td>Top countries: {topFiveCountries(eventItem.eventID).map((item,index) => (
                  <span key={index}>{index + 1}: {FindCountry(item)}{index < topFiveCountries(eventItem.eventID).length -1 && ","} </span>
                 ))}</td></tr>}
              {/* <p>Total registrations per day (unique): </p> */}
              </tbody>
              </table>
            </section>
          ))}
        </section>
    </div>
  )
}

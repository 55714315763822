import "./../App.css";
import logo from "./../images/hologic_desktop2.svg";
import { useLocation,useNavigate } from "react-router-dom";

export const Header = ({state,Language,Logout}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <header>
      <div className="preHeader">
        {state.loggedIn && !location.pathname.includes("self-register") && <span className="headerRole">{state.role === "super" ? "Logged in as admin" : "Logged in as " + state.role}</span>}
        {state.loggedIn && !location.pathname.includes("self-register") && 
          <div>
            {(state.role === "super" || state.role === "manager" || state.role === "reception") && <span onClick={() => navigate("/registration-links")}>Self-registration<span className="showDesktop"> links</span></span>}
            {(state.role === "super" || state.role === "manager" || state.role === "reception") && <span>|</span>}
            {(state.role === "super" || state.role === "manager") && <span onClick={() => navigate("/data-export")}><span className="showDesktop">Data </span>Export</span>}
            {(state.role === "super" || state.role === "manager") && <span>|</span>}
            {(state.role === "super" || state.role === "manager") && <span onClick={() => navigate("/admin")}>Admin<span className="showDesktop"> users</span></span>}
            {(state.role === "super" || state.role === "manager") && <span>|</span>}
            {(state.role === "super" || state.role === "manager") && <span onClick={() => navigate("/event-dashboard")}><span className="showDesktop">Event </span>Dashboard</span>}
            {state.role === "reception" && <span onClick={() => navigate("/search-participants/" + state.receptionEventID)}>Search<span className="showDesktop"> for participant</span></span>}
            <span>|</span>
            <span onClick={() => Logout()}>Log out</span>
          </div>
        }
      </div>
      <div className="mainHeader">
        <div><img onClick={() => location.pathname.includes("self") ? null : navigate("/")} src={logo} alt="Hologic" /></div>
        {!location.pathname.includes("self") && <div className="aligncenter">Workshop Registration 2024</div>}
        {/* <div className="alignRight">{location.pathname === "/" && (state.role ==="super" || state.role === "manager") && state.loggedIn && <button className="home new header half" onClick={() => navigate("new-event")}>new event <i className="fas fa-plus"></i></button>}</div> */}
        <div className="alignRight">{location.pathname === "/" && state.role ==="super" && state.loggedIn && <button className="home new header half" onClick={() => navigate("new-event")}>new event <i className="fas fa-plus"></i></button>}</div>
      </div>
    </header>
  )
}

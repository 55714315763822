import "./../App.css";
import React, { useState } from 'react';
import { QR } from "./../components/QR.js";
import { CompareStartDateThenTime } from "./../Methods.js";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const SelfRegLinksEvent = ({ Back, state }) => {

    const [copyAlert,setCopyAlert] = useState(false);
    const { eventID } = useParams();
    const navigate = useNavigate();

    return (
        <div>
            <button className="secondary" onClick={() => Back(true)}>Back</button>
            <section>
                <h1>{state.events[eventID].eventName} - Self-Registration Links</h1>
                {Object.values(state.activities).filter(item => item.eventID === eventID).filter(item => item.open).length > 0 ? Object.values(state.activities)
                    .filter(item => item.eventID === eventID)
                    .filter(item => item.open)
                    .sort(CompareStartDateThenTime)
                    .map((activityItem, activityIndex) => (
                        <div key={activityIndex} className="card justify">
                            <div className="qrGroup" style={{flex: 5}}>
                                <div>
                                    <QR passedURL={'self-register/' + activityItem.eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID} />
                                </div>
                                <div className="qrSpacer" />
                                <div>
                                    <h3 className="qrH3">{activityItem.activityName}</h3>
                                    <p className="less">{activityItem.startDate.split("-").reverse().join("-")} | {activityItem.startTime} hrs</p>
                                    <button className="hundred" onClick={() => { setCopyAlert(true); navigator.clipboard.writeText(window.location.origin + '/self-register/' + activityItem.eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID ) }}>Copy URL</button>
                                </div>
                            </div>
                            <div style={{flex: 1}}>
                                <span className={(state.events[eventID].selfReg && activityItem.selfReg) ? "dot green" : "dot red"}></span>
                                <span className={state.role === "reception" ? "selfRegEdit" : "selfRegEdit link"} onClick={() => state.role === "reception" ? null : navigate("/edit-event/" + activityItem.eventID)}>Event self-registration {(state.events[eventID].selfReg && activityItem.selfReg) ? "open" : "disabled"}</span>
                            </div>
                        </div>
                    )) : <p>No activities open</p>}
            </section>
            {copyAlert && <div className="modal">
                <div>
                    <p>URL copied to your clipboard</p>
                    <button onClick={() => { setCopyAlert(false) }}>OK</button>
                </div>
            </div>}
        </div>
    )
}

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import React, { useEffect, useState } from 'react';
import "./../App.css";
import {storage} from './../firebase';
import { getDownloadURL, ref } from "firebase/storage";
import {SelfRegisterCard} from "./../components/SelfRegisterCard.js";
import {CompareActivityName,CompareStartDateThenTime} from "./../Methods.js";
import { useParams } from "react-router-dom";
import {PDF} from './../components/PDF.js';

export const SelfRegisterEventPage = ({state,handleChange,translation}) => {
  const {eventID} = useParams();
  const eventItem = state.events[eventID];
  const [showPDF,setShowPDF] = useState(false);
  const [url, setURL] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const ViewPDF = (activityItem) => {
    if (activityItem.pdf) {
      window.scrollTo(0, 0);
      document.body.classList.add('modal-open');
      const pdfRef = ref(storage, 'activity-pdfs/' + eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID + '/' + activityItem.pdf);
      // Get the download URL
      getDownloadURL(pdfRef)
        .then((url) => {
          setURL(url);
          setShowPDF(true);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    }
  }

  const closePDF = () => {
    document.body.classList.remove('modal-open');
    setShowPDF(false)
  }

  if (eventItem) return (
    <div>
      {Object.values(state.events)
        .filter(item => (item.eventID.toString() === eventID))
        .map((eventItem,eventIndex) => (
        <section className="event" key={eventIndex}>
          <div className="justify">
            <h1>{translation[eventItem.language].registerFor} {eventItem.eventName}</h1>
            {!eventItem.selfReg && <div><span className="dot red"></span><span>{translation[eventItem.language].regNotOpen}</span></div>}
          </div>
          <div className="activityGroup reception">
            {Object.values(state.activities).filter(item => (item.eventID === eventID && item.open)).length > 1 && <div className="select-wrapper auto">
              <select className="sort auto" value={state.sort} onChange={handleChange} name="sort">
                <option value="date">{translation[eventItem.language].sortDate}</option>
                <option value="alpha">{translation[eventItem.language].sortAlpha}</option>
              </select>
            </div>}
            <div className="cards">
              {Object.values(state.activities).filter(item => (item.eventID === eventID && item.open)).length > 0 ? Object.values(state.activities).filter(item => (item.eventID === eventID && item.open)).sort(state.sort === "date" ? CompareStartDateThenTime : CompareActivityName).map((item,index) => (
                <SelfRegisterCard translation={translation} ViewPDF={ViewPDF} eventItem={eventItem} key={index} index={index} item={item} state={state} />
              )) : <p>{translation[eventItem.language].regNotOpen}</p>}
            </div>
          </div>
        </section>
      ))}
      {showPDF && 
        <div style={{position:'absolute',left:0,right:0,top:0}}>
          <button className="modalHeader" onClick={() => closePDF()}>{translation[eventItem.language].close}</button>
          <PDF url={url} />
        </div>
      }
    </div>
  )
  else return null;
}

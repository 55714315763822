import React,{ useEffect } from 'react';
import {ConvertTime, Capitalize, FindCountry} from "./../Methods.js";
import { useCSVDownloader } from 'react-papaparse';

export default function CSVDownloader({eventID, state, optIn}) {
  const { CSVDownloader, Type } = useCSVDownloader();
  let data = [];
  let optInData = [];

  useEffect(() => {
      // Object.values(state.events).filter(item => eventID ? item.eventID === eventID : item).forEach(eventItem => {
      let myData = Object.values(state.users).filter(item => eventID ? item.eventID === eventID && item.role === "user" && item.optIn : item.role === "user" && item.optIn);
        const filterData = Object.values(
          myData.reduce( (c, e) => {
            if (!c[e.email]) c[e.email] = e;
            return c;
          }, {})
        );
      filterData.forEach(registeredItem => {
        optInData.push({
          'First name': registeredItem.firstName,
          'Last name': registeredItem.lastName,
          'Email address': registeredItem.email,
          'Title': registeredItem.title,
          'Phone': registeredItem.phone,
          'Institution': registeredItem.institution,
          'Address': registeredItem.address,
          // 'Country': registeredItem.country || "",
          'Country': FindCountry(registeredItem.country),
          'Comment': registeredItem.comment,
          'Event': state.events[registeredItem.eventID].eventName,
          'Group': state.activityGroups[registeredItem.activityGroupID].activityGroupName,
          'Activity': state.activities[registeredItem.activityID].activityName,
          'Date': state.activities[registeredItem.activityID].startDate,
          'Start time': state.activities[registeredItem.activityID].startTime,
          'Checked-in': registeredItem.checkedIn ? "Yes" : "No",
          'Status': Capitalize(registeredItem.userType),
          'Privacy Policy accepted': registeredItem.gdpr ? "Yes" : "No",
          'Opt-In email marketing': registeredItem.optIn ? "Yes" : "No",
          'Opt-In activity email': registeredItem.emailConfirmation ? "Yes" : "No",
          'Email delivery': registeredItem.emailError ? "Error" : "OK",
          'Registered date': ConvertTime(registeredItem.timeStamp),
          'Last updated': ConvertTime(registeredItem.lastUpdated)
        });
      })
      Object.values(state.users).filter(item => eventID ? item.eventID === eventID && item.role === "user" : item.role === "user").forEach(registeredItem => {
        data.push({
          'First name': registeredItem.firstName,
          'Last name': registeredItem.lastName,
          'Email address': registeredItem.email,
          'Title': registeredItem.title,
          'Phone': registeredItem.phone,
          'Institution': registeredItem.institution,
          'Address': registeredItem.address,
          // 'Country': registeredItem.country || "",
          'Country': FindCountry(registeredItem.country),
          'Comment': registeredItem.comment,
          'Event': state.events[registeredItem.eventID].eventName,
          'Group': state.activityGroups[registeredItem.activityGroupID].activityGroupName,
          'Activity': state.activities[registeredItem.activityID].activityName,
          'Date': state.activities[registeredItem.activityID].startDate,
          'Start time': state.activities[registeredItem.activityID].startTime,
          'Checked-in': registeredItem.checkedIn ? "Yes" : "No",
          'Status': Capitalize(registeredItem.userType),
          'Privacy Policy accepted': registeredItem.gdpr ? "Yes" : "No",
          'Opt-In email marketing': registeredItem.optIn ? "Yes" : "No",
          'Opt-In activity email': registeredItem.emailConfirmation ? "Yes" : "No",
          'Email delivery': registeredItem.emailError ? "Error" : "OK",
          'Registered date': ConvertTime(registeredItem.timeStamp),
          'Last updated': ConvertTime(registeredItem.lastUpdated)
        });
      })
      // console.log(optInData.length);
  },[])
  return (
    <CSVDownloader
      type={Type.Button}
      filename={eventID ? "event-data" : "all-workshop-data"}
      bom={true}
      config={
        {
          delimiter: ',',
        }
      }
      data={() => {
        return optIn ? optInData : data
      }}
    >
      {eventID ? optIn ? "Export opt-in" : "Export all" : "Export all app data"}
    </CSVDownloader>
  );
}
